<template>
  <div :class="{ dialog: isDialog }">
    <form ref="form" @submit.stop.prevent="onSubmit" class="form">
      <b-form-group label-for="name-input">
        <div class="label" slot="label"><span>*</span>姓名：</div>
        <b-form-input
          id="name-input"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
          size="lg"
          required
          aria-describedby="input-1-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-1-live-feedback"
          >请输入长度不超过20位的姓名</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group label-for="name-company">
        <div class="label" slot="label"><span>*</span>公司名称：</div>
        <b-form-input
          id="name-company"
          v-model="$v.form.company.$model"
          :state="validateState('company')"
          size="lg"
          required
          aria-describedby="input-1-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-1-live-feedback"
          >请输入长度不超过20位的公司名称</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group label-for="name-position">
        <div class="label" slot="label"><span>*</span>岗位：</div>
        <b-form-input
          id="name-position"
          v-model="$v.form.position.$model"
          :state="validateState('position')"
          size="lg"
          required
          aria-describedby="input-1-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-1-live-feedback"
          >请输入长度不超过20位的岗位名称</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group label-for="name-mobile">
        <div class="label" slot="label"><span>*</span>联系电话：</div>
        <b-form-input
          id="name-mobile"
          v-model="$v.form.mobile.$model"
          :state="validateState('mobile')"
          size="lg"
          aria-describedby="input-2-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-2-live-feedback"
          >请输入合法的手机号码</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group label-for="name-email">
        <div class="label" slot="label"><span>*</span>电子邮箱：</div>
        <b-form-input
          id="name-email"
          v-model="form.email"
          :state="validateState('email')"
          size="lg"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-for="name-scene">
        <div class="label" slot="label"><span>*</span>合作需求：</div>
        <b-form-input
          id="name-scene"
          v-model="form.scene"
          :state="validateState('scene')"
          size="lg"
        ></b-form-input>
      </b-form-group>
      <!-- <b-form-group label-for="name-industry">
      <div class="label" slot="label"><span>*</span>行业：</div>
      <b-form-input
        id="name-industry"
        v-model="form.industry"
        size="lg"
      ></b-form-input>
    </b-form-group>
    <b-form-group label-for="name-sence">
      <div class="label" slot="label"><span>*</span>应用场景：</div>
      <b-form-textarea
        id="name-sence"
        v-model="form.sence"
        rows="2"
        max-rows="4"
        size="lg"
      ></b-form-textarea>
    </b-form-group> -->
      <b-form-group
        label="您是如何知道“智能数字教练”的？（多选）"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="approach"
          :aria-describedby="ariaDescribedby"
          name="flavour-2"
        >
          <b-row>
            <b-col cols="4">
              <b-form-checkbox value="1" size="lg">网络搜索</b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox value="2" size="lg">朋友介绍</b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox value="4" size="lg">市场活动</b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox value="8" size="lg">短视频</b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox value="16" size="lg">电商协会</b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>
      <!-- <div class="w-100">
      <b-button
        class="w-100 btn-apply"
        variant="primary"
        size="lg"
        @click="handleOk"
      >
        提交
      </b-button>
    </div> -->
    </form>
    <div class="page-footer fz-14">
      <b-form-checkbox
        id="checkbox-1"
        v-model="$v.form.agree.$model"
        :state="validateState('agree')"
        name="checkbox-1"
        class="fz-14 a_center"
        value="1"
        size="lg"
        required
        unchecked-value=""
      >
        我已阅读并同意<a
          :href="protocolUrl"
          :target="isDialog ? '_blank' : '_self'"
          >《隐私条款》</a
        >
      </b-form-checkbox>
      <b-row class="btns">
        <b-col v-if="!isDialog">
          <b-button
            class="w-100"
            variant="outline-primary"
            size="lg"
            @click="handleBack"
          >
            返回
          </b-button>
        </b-col>
        <b-col>
          <b-button
            class="w-100 btn-apply"
            variant="primary"
            size="lg"
            @click="handleOk"
          >
            提交
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import CommonApi from "@/api/CommonApi";
import utils from "@/utils";
const commonApi = new CommonApi();
const mobileValidate = (value) => /^1[3456789]\d{9}$/.test(value);
const emailValidate = (value) => /^(.+)@(.+)$/.test(value);
const protocolUrl = process.env.VUE_APP_PROTOCOL_URL;
export default {
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      name: "",
      form: {
        name: null,
        mobile: null,
        company: null,
        email: "",
        position: "",
        agree: "",
        // industry: "",
        sence: "",
      },
      approach: [],
      status: false,
      isDialog: !utils.isMobile(),
      protocolUrl: protocolUrl,
    };
  },
  computed: {},
  validations: {
    form: {
      name: {
        required,
        minLength: maxLength(20),
      },
      company: {
        required,
        minLength: maxLength(20),
      },
      position: {
        required,
        minLength: maxLength(20),
      },
      scene: {
        required,
        minLength: maxLength(200),
      },
      mobile: {
        required,
        mobileValidate,
      },
      agree: {
        required,
      },
      email: {
        required,
        emailValidate,
      },
    },
  },
  mounted() {
    // console.log();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        name: null,
        mobile: null,
        company: null,
        email: "",
        position: "",
        agree: "",
      };
      this.approach = [];

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const { name, company, position, mobile, scene, email } = this.form;
      const approach = this.approach.reduce((prev, next) => {
        return prev + Number(next);
      }, 0);
      //   const html = `<p>姓名：<b>${name}</b></p>
      // <p>公司名称：<b>${company}</b></p>
      // <p>岗位：<b>${position}</b></p>
      // <p>联系电话：<b>${mobile}</b></p>
      // <p>电子邮箱：<b>${email}</b></p>
      // <p>合作需求：<b>${scene}</b></p>
      // <p>了解渠道：<b>${approach}</b></p>`;
      // console.log(html);
      commonApi
        .submitApply({
          name,
          company,
          position,
          mobile,
          scene,
          email,
          approach,
          type: 1,
        })
        .then((res) => {
          console.log(res);
          if (res.successed) {
            this.$bvModal.hide("modal-prevent-closing");
            setTimeout(() => {
              this.$bvModal
                .msgBoxOk("感谢您的申请，我们会尽快安排人员与您联系", {
                  title: "提交成功",
                  size: "md",
                  buttonSize: "md",
                  okTitle: "我知道了",
                  okVariant: "primary",
                  headerClass: "p-20 border-bottom-0",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                })
                .then(() => {
                  this.handleBack();
                });
            }, 500);
          }
        })
        .finally(() => {});
    },
    resetModal() {
      this.resetForm();
    },
    handleOk(/*bvModalEvent*/) {
      this.onSubmit();
    },
    handleBack() {
      console.log("返回");
      this.$router.back();
    },
    apply() {
      this.$bvModal.show("modal-prevent-closing");
    },
  },
  beforeDestroy() {
    this.resetForm();
  },
};
</script>

<style lang="scss" scoped>
@function px2rem($value, $ratio) {
  @return $value / $ratio + 0rem;
}
.row-reverse {
  flex-direction: row-reverse;
}
.column-reverse {
  flex-direction: column-reverse;
}

.btn-apply {
  background: #5769ff;
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: #6f57ff;
  }
}
.submit-head {
  display: flex;
  align-items: center;
  padding-left: 1.67rem;
  width: 100%;
  height: 6.75rem;
  background: #f3f5ff;
  border-bottom: 1px solid rgba($color: #5769ff, $alpha: 0.2);
  .form-head {
    width: 22.33rem;
  }
}

.form {
  margin-bottom: 9.83rem;
  padding: 1.42rem 1.67rem;
  text-align: left;
}

.label {
  span {
    padding-right: 5px;
    color: #ec463c;
  }
}

.page-footer {
  border-top: 1px solid rgba(#5769ff, 0.2);
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 9.83rem;
  background: #ffffff;
  z-index: 2;

  .btns {
    margin-top: 1rem;
  }
}

.dialog {
  .page-footer {
    padding: 1rem 0;
    position: relative;
  }

  .form {
    padding: 0;
    margin-bottom: 0;
  }
}
.modal-dialog-scrollable .modal-body {
  overflow-x: hidden;
}
</style>

<style>
.page-footer .custom-control-label {
  font-size: 14px;
  line-height: 2;
}
.ismobile .page-footer .custom-control-label {
  font-size: 12px;
}
.page-footer .custom-control-input.is-valid ~ .custom-control-label {
  color: #212529;
}
.page-footer
  .custom-control-input.is-valid:checked
  ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
</style>