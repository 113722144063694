import axios from "axios";

export default class Request {
  constructor() {
    this.service = axios.create({
      baseURL: process.env.VUE_APP_API+"/api/",
      timeout: 15000,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      transformRequest: [
        function (data) {
          if (data instanceof FormData) return data;
          let newData = "";
          for (let k in data) {
            if (Object.prototype.hasOwnProperty.call(data, k) === true) {
              newData +=
                encodeURIComponent(k) + "=" + encodeURIComponent(data[k]) + "&";
            }
          }
          return newData;
        },
      ],
    });

    /**
     * 请求拦截器，每次请求发起之前都会经过此拦截器
     */
    this.service.interceptors.request.use(
      (config) => {
        // 去除一些请求不必要的动画
        if (config.params && config.params.animate) {
          delete config.params.animate;
        } else if (config.animate) {
          delete config.animate;
        } else if (config.data && config.data.animate) {
          delete config.data.animate;
        } else {
          // NProgress.start()
        }
        // 如果请求的url带有http的不使用默认域名
        if (
          config.url.indexOf("http") > -1 ||
          config.url.indexOf("https") > -1
        ) {
          config.baseURL = "";
        }
        let obj = {
          // token: usercache.getUserToken(),
          // browser: utils.browse(),
          // os: utils.clientOs(),
          // apptype: 1,
        };
        // if (
        //   (config.params && config.params.verifyservice) ||
        //   location.href.indexOf("/wxcall/train") > -1 ||
        //   location.href.indexOf("/wxcall/register") > -1
        // ) {
        //   obj.os = "mini";
        // }
        config.headers = Object.assign(config.headers, obj);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    /**
     * 请求接口后返回的拦截器
     */
    this.service.interceptors.response.use(
      (res) => {
        // NProgress.done()
        // 请求成功
        const { status, data } = res;
        return new Promise((reslove, reject) => {
          ///接口返回成功
          if (status === 200 && data.successed) return reslove(data);
          if (
            status === 200 &&
            data.successed == undefined &&
            data.status != 404 &&
            (typeof data == "object" || Array.isArray(data))
          )
            return reslove(data);
          let { errorcode } = data;
          ///接口返回的错误信息为token失效或登录状态失效
          if (
            errorcode == 10000 ||
            errorcode == 9999 ||
            errorcode == 9998 ||
            errorcode == 9100 ||
            errorcode == 10001
          ) {
            // store.dispatch("user/logout", errorcode).then(() => {
            //   // location.reload()
            // });
          }
          return reject(data);
        });
      },
      (error) => {
        // NProgress.done();
        console.log(error.response);
        return Promise.reject(error.response);
      }
    );
  }
  /**
   * axios service 对象实例
   */
  request() {
    return this.service;
  }

  /**
   * 单例模式，返回http 请求对象
   */
  static getInstance() {
    if (Request._instance) return Request._instance;
    console.log("from cache");
    Request._instance = new Request();
    return Request._instance;
  }
}
