import request from "./request";
// const IMAGE_BASE = process.env.VUE_APP_IMAGE_BASE;
/**
 * 数据处理业务基类
 */
export default class ApiHelper {
  constructor() {
    this.request = request.getInstance().request();
  }

  getList(url, data) {
    return this.request.get(url, data);
  }
  /**
   *
   */
  getDetails(url, id) {
    return this.request.get(url + `/${id}`);
  }
  /**
   *
   */
  insert(url, data) {
    return this.request.post(url, data);
  }
  /**
   * @param {Object} url
   * @param {Object} data
   */
  update(url, data) {
    return this.request.put(url, data);
  }
  /**
   * @param {Object} url
   * @param {Object} data
   */
  deleteData(url, data) {
    return this.request.delete(url, data);
  }

  delete(url, id) {
    return this.request.delete(url + `/${id}`);
  }

  /**
   * 批量操作
   */
  setBatchsetOpt(url, params) {
    return this.put(url, params);
  }

  /**请求 */
  get(url, data) {
    return this.request.get(url, data);
  }
  post(url, data) {
    return this.request.post(url, data);
  }
  put(url, data) {
    return this.request.put(url, data);
  }
  //delete(url,data){  return this.request.delete(url,data);}
  /**
   * 直接获取html的内容
   * @param {*} url
   */
  getHtml(url) {
    console.log(url);
    //   if (url.indexOf('http://')>=0 || url.indexOf('https://')>=0) return this.request.get(url)
    //   return this.request.get(`${IMAGE_BASE}${url}`)
  }
}
