import DataApi from "./APIHelper";

export default class CommonApi extends DataApi {
  constructor() {
    super();
  }

  // 获取新闻列表
  // getNewsList(cid = 1, position = "", page) {
  //   return this.get(`document?cid=${cid}&position=${position}&page=${page}`);
  // }

  // 提交表单
  submitApply(data) {
    return this.post('wechat/website/apply', data);
  }

  // 保存安全设置权限 列表
  // putListSafeSetting(id, acltype, data) {
  //   return this.put(
  //     `api/admin/material/acl/bizdata/${id}?type=${acltype}`,
  //     data
  //   );
  // }
}
